import { IStrategy } from '@dreamcommerce/star_core';
import { TWebapiResponse } from '@storefrontRoot/app/base_http_api/base_http_api_types';
import { RESPONSE_STATUS } from '@storefrontRoot/core_features/http_requester/response_constants';
import { TResponseStatus } from '@storefrontRoot/core_features/http_requester/response_types';

export class StatusDecorator implements IStrategy<TWebapiResponse, TResponseStatus> {
    public execute(response: TWebapiResponse): TResponseStatus {
        return response.result ? RESPONSE_STATUS.success : RESPONSE_STATUS.failed;
    }
}

import { EventHandler } from '@dreamcommerce/star_core';
import { ComponentsIntersectionObserverConstructorOptions } from '@storefrontCoreFeatures/components/management/observers/intersection/components_intersection_observer_types';
import { ComponentsUtils } from '@storefrontCoreFeatures/components/components_utils';
import { COMPONENTS_PAGE_EVENTS_OBSERVER } from '@storefrontCoreFeatures/components/management/components_management_constants';
import { IComponentsResolver } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_types';

export class ComponentsPageEventsObserver extends EventHandler {
    public moduleName = COMPONENTS_PAGE_EVENTS_OBSERVER;

    #componentsResolver: IComponentsResolver;

    constructor({ componentsResolver }: ComponentsIntersectionObserverConstructorOptions) {
        super();

        this.#componentsResolver = componentsResolver;

        this.initializeComponents();
    }

    public initializeComponents = (): void => {
        const componentsToInitialize = ComponentsUtils.filterComponentsToInitialize(Array.from(document.querySelectorAll<HTMLElement>('*')));

        this.#componentsResolver.resolve(componentsToInitialize);
    };

    public initializeComponentsInBasketModules = (): void => {
        const componentsToInitialize = ComponentsUtils.filterComponentsToInitialize(
            Array.from(document.querySelectorAll<HTMLElement>('[data-module-name*="basket"] *'))
        );

        this.#componentsResolver.resolve(componentsToInitialize);
    };
}

import { FeatureCore } from '@dreamcommerce/star_core';
import { ComponentsIntersectionObserverConstructorOptions } from '@storefrontCoreFeatures/components/management/observers/intersection/components_intersection_observer_types';
import { COMPONENTS_INTERSECTION_OBSERVER_NAME } from '@storefrontCoreFeatures/components/management/components_management_constants';
import { IComponentsResolver } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_types';
import { LAZY_COMPONENT_ATTRIBUTE_NAME } from '@storefrontCoreFeatures/components/management/observers/components_observers_constants';
import { PAGE_MANAGER_EVENTS } from '@storefrontCoreFeatures/page_management/turbo_message_names';

export class ComponentsIntersectionObserver extends FeatureCore {
    #observer: IntersectionObserver;

    public moduleName = COMPONENTS_INTERSECTION_OBSERVER_NAME;

    #componentsResolver: IComponentsResolver;

    constructor({ componentsResolver }: ComponentsIntersectionObserverConstructorOptions) {
        super();

        this.#componentsResolver = componentsResolver;
        this.#observer = new IntersectionObserver(this.#handleIntersection, {
            // TODO to może być jeszcze do dostrojenia
            rootMargin: '20px',
            threshold: 0.25
        });

        this.initObservers(this._getElementsToObserve());

        this.eventBus.on(PAGE_MANAGER_EVENTS.loaded, this.#handleDynamicallyAddedElements);
    }

    #handleIntersection: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(({ isIntersecting, target }) => {
            if (isIntersecting) {
                this.#observer.unobserve(target);
                this.#componentsResolver.resolve([target as HTMLElement]);
            }
        });
    };

    public initObservers(elements: HTMLElement[]): void {
        elements.forEach((el$) => {
            this.#observer.observe(el$);
        });
    }

    private _getElementsToObserve(): HTMLElement[] {
        return Array.from(document.querySelectorAll<HTMLElement>(`[${LAZY_COMPONENT_ATTRIBUTE_NAME}]`));
    }

    #handleDynamicallyAddedElements = (): void => {
        this.initObservers(this._getElementsToObserve());
    };
}

import { FeatureCore } from '@dreamcommerce/star_core';
import { ComponentsIntersectionObserverConstructorOptions } from '@storefrontCoreFeatures/components/management/observers/intersection/components_intersection_observer_types';
import { COMPONENTS_MUTATION_OBSERVER_NAME } from '@storefrontCoreFeatures/components/management/components_management_constants';
import { IComponentsResolver } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_types';
import { ComponentsUtils } from '@storefrontCoreFeatures/components/components_utils';

export class ComponentsMutationObserver extends FeatureCore {
    public readonly moduleName = COMPONENTS_MUTATION_OBSERVER_NAME;

    #observer: MutationObserver;
    #componentsResolver: IComponentsResolver;

    constructor({ componentsResolver }: ComponentsIntersectionObserverConstructorOptions) {
        super();

        this.#observer = new MutationObserver(this.#handleMutation);
        this.#componentsResolver = componentsResolver;

        this._initObserver();
    }

    private _initObserver(): void {
        this.#observer.observe(document.body.parentElement as HTMLElement, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }

    #handleMutation: MutationCallback = (mutations: MutationRecord[]): void => {
        for (const { type, addedNodes } of mutations)
            if (type === 'childList') {
                this._resolveComponents(Array.from(addedNodes));
            }
    };

    private _resolveComponents(nodes: Node[]): void {
        this.#componentsResolver.resolve(ComponentsUtils.filterComponentsToInitialize(ComponentsUtils.filterHtmlElements(nodes)));
    }
}

import {
    EventMessage,
    FeatureApi,
    IHTTPRequesterApi,
    IRequestOptions,
    IRequestResponse,
    IRequesterFetchItem,
    StrategiesContainer
} from '@dreamcommerce/star_core';
import {
    TDecoratedField,
    TDecoratorName,
    TFetchResponseProps,
    TWebapiResponse,
    UnauthorizedError
} from '@storefrontRoot/app/base_http_api/base_http_api_types';

import { BASE_HTTP_API_DECORATOR_STRATEGIES } from '@storefrontRoot/app/base_http_api/decorators/base_http_api_decorator_strategies';
import { BASE_HTTP_API_EVENTS } from '@storefrontApp/base_http_api/base_http_api_events';

export abstract class BaseHttpApi extends FeatureApi {
    #httpApi: IHTTPRequesterApi;
    #decorators: StrategiesContainer<TWebapiResponse, TDecoratedField>;

    constructor(httpApi: IHTTPRequesterApi) {
        super();

        this.#httpApi = httpApi;
        this.#decorators = new StrategiesContainer<TWebapiResponse, TDecoratedField>();

        for (const [decoratorName, decorator] of Object.entries(BASE_HTTP_API_DECORATOR_STRATEGIES)) {
            this.#decorators.add(decoratorName, decorator);
        }
    }

    private _globalErrorHandler(error: UnauthorizedError) {
        if (!!error.error_description && error.error_description === 'Unauthorized') {
            this.eventBus.emit(new EventMessage<never>(BASE_HTTP_API_EVENTS.globalUnauthorizedCatch));
        }

        // throw error;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    protected fetch<TMappedResponse, TOriginalResponse = {}>(
        options: IRequestOptions,
        { callback, decorators }: TFetchResponseProps<TOriginalResponse, TMappedResponse> = {}
    ): IRequesterFetchItem<TMappedResponse> {
        const { response: originalResponse, cancelRequest } = this.#httpApi.fetch<TOriginalResponse & TWebapiResponse>(options);

        const response = originalResponse.then((resp) => {
            const modifiedResponse = callback ? callback(resp) : resp;
            const decoratedData = decorators && this._decorateResponseData(resp.data, decorators);

            return {
                ...modifiedResponse,
                data: decoratedData
                    ? {
                          ...modifiedResponse.data,
                          ...decoratedData
                      }
                    : modifiedResponse.data
            };
        }) as Promise<IRequestResponse<TMappedResponse>>;

        response.catch(this._globalErrorHandler);

        return { response, cancelRequest };
    }

    private _decorateResponseData(responseData: TWebapiResponse, decorators: TDecoratorName[]) {
        return decorators.reduce(
            (totalFields, decorator) => ({
                ...totalFields,
                [decorator]: this._decorateField(decorator, responseData)
            }),
            {}
        );
    }

    private _decorateField(decorator: TDecoratorName, responseData: TWebapiResponse): TDecoratedField {
        this.#decorators.setCurrent(decorator);
        return this.#decorators.execute(responseData);
    }
}

import { WebComponentsUtils } from '@dreamcommerce/utilities';
import {
    LAZY_COMPONENT_ATTRIBUTE_NAME,
    ON_INTERACTION_COMPONENT_ATTRIBUTE_NAME
} from '@storefrontCoreFeatures/components/management/observers/components_observers_constants';

export class ComponentsUtils {
    public static isUnknownElement($element: Element): boolean {
        return $element instanceof HTMLUnknownElement;
    }

    public static isElementDefined($element: Element): boolean {
        if (!this.isCustomElement($element)) return false;

        const customElementClass = window.customElements.get($element.tagName.toLowerCase());

        return !!customElementClass && $element instanceof customElementClass;
    }

    public static isCustomElement($element: Element): boolean {
        return $element.tagName.includes('-');
    }

    public static isComponentLazy($element: HTMLElement): boolean {
        return $element.hasAttribute(LAZY_COMPONENT_ATTRIBUTE_NAME);
    }

    public static isLoadedOnInteraction($element: HTMLElement): boolean {
        return $element.hasAttribute(ON_INTERACTION_COMPONENT_ATTRIBUTE_NAME);
    }

    public static filterComponentsToInitialize(components: HTMLElement[]): HTMLElement[] {
        return components.filter(
            ($element) =>
                ComponentsUtils.isUnknownElement($element) ||
                (WebComponentsUtils.isWebComponent($element) &&
                    !WebComponentsUtils.isWebComponentDefined($element) &&
                    !ComponentsUtils.isComponentLazy($element) &&
                    !ComponentsUtils.isLoadedOnInteraction($element))
        );
    }

    public static filterHtmlElements(components: Node[]): HTMLElement[] {
        return components.filter((component: Node) => component instanceof HTMLElement) as HTMLElement[];
    }

    public static nextTick(cb: () => void): Promise<void> {
        return new Promise((resolve) => requestAnimationFrame(() => resolve(cb())));
    }
}
